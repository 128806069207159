import React from "react";

var Api_url = process.env.GATSBY_API_URL;
console.log("API_URL=" + Api_url);

var image_path = Api_url + `/assets/images/donation-success.png`;

const PaymentSuccessContent = () => {
    return (
        <section className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h3>Thank you for your contribution!</h3>
                        <img src={image_path} width="50%" height="auto" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PaymentSuccessContent;
