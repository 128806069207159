import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PaymentSuccessContent from "../components/PaymentSuccessContent";
// import MixerAreaTwo from "../components/MixerAreaTwo";
// import TeamArea from "../components/TeamArea";
// import FaqArea from "../components/FaqArea";
// import ServiceArea from "../components/ServiceArea";
// import ClientsLogoTwo from "../components/ClientsLogoTwo";
import Footer from "../components/Footer";

const AboutPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | Payment Success">
            <NavOne />
            <PaymentSuccessContent />
            <Footer />
        </Layout>
    );
};

export default AboutPage;
